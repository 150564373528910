
import { useUiStore } from '@/stores/ui'
import { WHITELISTED_PATHS } from '@/constants'

export default {
  name: 'BannerBonusBank',
  setup() {
    const uiStore = useUiStore()
    return {
      uiStore,
    }
  },

  mounted() {},
  methods: {
    openApp() {
      this.$openNativeApp()
    },
    closePopup() {
      this.uiStore.showBannerBonusBank = false
    },
  },
}
